import { Button, Col, Layout, Row, Typography, Input, Space } from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Content } from "antd/es/layout/layout";
import "./LoginPage.scss";
import * as Yup from 'yup';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { UserOutlined } from '@ant-design/icons';
import 'antd-css-utilities/utility.min.css';
import { useDispatch } from "react-redux";
import { useContext } from "react";
import SocketContext from "../../store/SocketContext";

const MyInput = (props) => {
  const onInputChangeHandler = (event) => {
 
    props.form.setFieldValue('username', event.target.value)
    if (props.errorMessage) {
        props.removeErrorMessage(props.field.name);
    }
  }

  return (<Input onChange={onInputChangeHandler}/>)
}

const LoginPage = () => {
    const { Title } = Typography;
    const initialValues = {
      username: "",
      password: ""
    };
    const socket = useContext(SocketContext);
    const validationSchema = Yup.object({
      username: Yup.string().required(),
      password: Yup.string().min(3).required()
    });
    
    const dispatch = useDispatch();
    const handleSubmit = (values) => {
      socket.emit("userLogin", values);
    };
  
    return (
      <Layout className="login-page-wrapper">
        <Content>
          <Row>
            <Col span={8}></Col>
            <Col span={8}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => {
                  return (
                    <Form>
                      <Space direction="vertical" size="middle">
                        <Title className='login-title' align="center">Login</Title>
                        <Field name="username">
                            {({ field, form }) => (
                              <Input
                                value={values.username}
                                type="text"
                                placeholder="User Name"
                                {...field}
                                onChange={e => form.setFieldValue('username', e.target.value)}
                              />
                            )}
                        </Field>
                        <ErrorMessage component="div" className="error" name="username" />
                        <Field name="password">
                            {({ field, form }) => (
                              <Input.Password
                                value={values.password}
                                type="text"
                                placeholder="Pasdsword"
                                {...field}
                                onChange={e => form.setFieldValue('password', e.target.value)}
                              />
                            )}
                        </Field>
                        <ErrorMessage component="div" className="error" name="password" />
                        <Button type="primary" htmlType="submit" onClick={handleSubmit} block>Login</Button>
                        </Space>
                    </Form>
                  )
                }}
              </Formik>
            </Col>
            <Col span={8}></Col>
          </Row>
        </Content>
      </Layout>
    );
  };
  
  export default LoginPage;
  