import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    themeMode: true,
    tablesList: [],
    showTalk: {
        allow: false,
        date: ''
    },
    changeMe: {
        allow: false,
        date: ''
    }
};

const dashboardSlice = createSlice({
    name: "table",
    initialState,
    reducers: {
        swithcThemeMode(state){
            state.themeMode = !state.themeMode
        },
        setTablesList(state, action) {
            state.tablesList = action.payload;
        },
        enableShowTalk(state) {
            state.showTalk.allow = true;
            state.showTalk.date = new Date();
        },
        disabeleShowTalk(state) {
            state.showTalk.allow = false;
            state.showTalk.date = new Date();
        },
        enableChangeMe(state) {
            state.changeMe.allow = true;
            state.changeMe.date = new Date();
        },
        disabeleChangeMe(state) {
            state.changeMe.allow = false;
            state.changeMe.date = new Date();
        },
    }
});

export const dashboardActions = dashboardSlice.actions;
export const dashboardReducer = dashboardSlice.reducer;