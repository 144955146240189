
import "./loader.scss"

const Loader = () => {
    
    return (<div className="loader-wrapper">
        <img src="/images/loader.gif" />
    </div>)
}

export default Loader;