import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./slice/usersSlice";
import { dashboardReducer } from "./slice/dashboardSlice";
import { gameReducer } from "./slice/gameSlice";
import { chatReducer } from "./slice/chatSlice";
import { dealerReducer } from "./slice/dealerSlice";
import { roundReducer } from "./slice/RoundsSlice";
import { betReducer } from "./slice/BetsSlice";

export default configureStore({
    reducer: {
        user: userReducer,
        dashboard: dashboardReducer,
        game: gameReducer,
        chat: chatReducer,
        dealer: dealerReducer,
        rounds: roundReducer,
        bets: betReducer
    }
})