import { useState } from "react";
import "./index.scss";

const Card = (props) => {
    const {cardName = '', adminOptions = {}} = props;

    /*
    * ToDo here need to check
    */
    let [type, must] = cardName.split('');

    if (type === 'T') {
        type = 10;
    }
  
    const emptyName = 'emptyCard';
    const emptyActiveName = 'emptyActiveCard';
    let emptyList = [emptyName, emptyActiveName]
    let emptycardClassName = 'opned-card';

    if (cardName === emptyName) {
        emptycardClassName = 'empty-card';
    }
    else if (cardName === emptyActiveName) {
        emptycardClassName = 'empty-active-card';
    }
    
    return (
        <div className={`card-wrapper ${emptycardClassName} ${props.className}`}>
            <div className="card-content">
                {emptyList.includes(cardName) === false  ? 
                    <>
                        <div className={`card-number card-number-${must}`}>{type}</div>
                        <div className={`card-must suit-${must}`}></div>
                    </>
                : '' }
            </div>
            {props?.adminOptions?.showRemove ? (<div className="card-extra-info">
                <span onClick={() => props?.adminOptions.removeCard(cardName)} className="card-close">X</span>
            </div>): ''}
        </div>
    )
}

export default Card;