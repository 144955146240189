import { createSlice } from "@reduxjs/toolkit";
import authData from "../../services/authData";
const initialState = {
    currentConf: {},
    currentRound: {},
    error: {
        status: false,
        message: '',
        date: ''
    }
};

const gameSlice = createSlice({
    name: "game",
    initialState,
    reducers: {
        update(state, action) {
            const data = action.payload;
            Object.keys(data).forEach((current) => {
                state[current] = data[current];
            })
        },
        error(state, action) {
            state.error = {
                status: true,
                message: action.payload,
                date: new Date()
            }
        },
        resetError(state) {
            state.error = {
                status: false,
                message: ''
            }
        }
    },
    extraReducers: (builder) => {

    }
});

export const gameActions = gameSlice.actions;
export const gameReducer = gameSlice.reducer;