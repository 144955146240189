import { Col, Row, Typography, Space } from "antd";
import "./Belote.scss";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { object } from "yup";
import BeloteContent from "./BeloteContent";

const Belote = ({adminOptions, table}) => {
    const game = useSelector(state => state.game);    

    const defaultData = {p1: [], p2: [], trump: {}}
    
    const data = (game?.cards) ? game : defaultData;

    return (<BeloteContent game={data} adminOptions={adminOptions} />)
}

export default Belote;