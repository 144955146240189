import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authData from "../../services/authData";

const initialState = {
    loaded: false,
    isLoggedin: false,
    user: {
        
    }
};

export const useLogin = createAsyncThunk('users/me', async() => {
    return {}
})

export const Me = createAsyncThunk('users/me', async() => {
    return {}
})

const usersSlice = createSlice({
    name: "counter",
    initialState,
    reducers: {
        updateUser(state, action) {
            state.loaded = true;
            const {payload} = action;
            if (payload.user) {
                state.user = payload.user;
            }
            state.isLoggedin = payload.isLoggedin;
            if (payload.accessToken) {
                authData.set(payload.accessToken, true)
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(Me.fulfilled, (state, action) => {
        })
    }
});

export const userActions = usersSlice.actions;
export const userReducer = usersSlice.reducer;