import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    messages: [],
    blockedIds: [],
    playerMessages: [],
    playerToDealerMessages: []
};

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {
        addMessage(state, action){
            state.messages.unshift(action.payload)
        },
        clearMessages(state) {
            state.messages = [];
            state.playerMessages = []
            state.playerToDealerMessages = [];
        },
        updatePlayerToDealerMessages(state, action) {
            state.playerToDealerMessages = action.payload;
        },
        updatePlayerMessages(state, action) {
            state.playerMessages = action.payload;
        },
        addPlayerMessage(state, action) {
            state.playerMessages.push(action.payload)
        },
        addPlayerToDealerMessage(state, action) {
            state.playerToDealerMessages.unshift(action.payload)
        },
        deletePlayerToDealerMessage(state, action) {
            state.playerToDealerMessages = state.playerToDealerMessages.filter((current) => {
                return current.id !== action.payload;
            })
        },
        setBlockedIds(state, action) {
            state.blockedIds = action.payload;
        },
        updateBlockedId(state, action) {
            const data = action.payload;
            if (data.status) {
                state.blockedIds.push(data.playerId)
            }
            else {
                state.blockedIds = state.blockedIds.filter(currentId => {
                    return currentId !== data.playerId;
                })
            }
        } 
    }
});

export const chatActions = chatSlice.actions;
export const chatReducer = chatSlice.reducer;