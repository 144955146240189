import { Row, Col, Typography } from "antd";
import { useTranslation } from 'react-i18next';

const Combinations = ({combinations, player = false, canceled = false}) => {
    const {Title, Text} = Typography;
    let canceledClass = canceled ? 'canceled-combination': '';
    const { t } = useTranslation("translation");

    return(<Row className="combination-info-wrapper">
        <Col span={4}>
        </Col>
        <Col span={20}>
            { Array.isArray(combinations) && <Title level={4} className="combinations-title">{t("Combinations")}: </Title>}
            {
                Array.isArray(combinations) && (
                    combinations.map((current, i) => {
                        if (current === 'belote') {
                            canceledClass = '';
                        }
                        return (<Title key={player+i+current} className={`combination-title ${canceledClass}`}>{t(current)} { i+1 !== combinations.length ? ',': ''}</Title>)
                    })
                )
            }
        </Col>
    </Row>)
}

export default Combinations;