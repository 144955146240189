import { TabletOutlined, UserOutlined, InsertRowBelowOutlined, LogoutOutlined, DollarOutlined, TeamOutlined, SolutionOutlined } from '@ant-design/icons';
import { Layout, Menu, theme  } from 'antd';
import React, { useState, lazy, Suspense, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import authData from '../../services/authData';
import "./AdminPanel.scss";
import ManagerActions from "./menus/Tables/Table/TableContent/ManagerActions/ManagerActions"
const { Header, Content, Footer, Sider } = Layout;

const AdminPanel = () => {
  function printComponent(menuItem) {
    console.log("menuItem ", menuItem)
    const DynamicComponent = lazy(() => import(`./menus/${menuItem}/${menuItem}View.js`));
    
    return (
      <div>
        <Suspense fallback={<div>Loading...</div>}>
          <DynamicComponent />
        </Suspense>
      </div>
    );
  }
  const userStore = useSelector((store) => store.user);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const location = useLocation();
  const pathName = location.pathname;
  let pathParams = pathName.split('/');
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  let defaultMenuItem = 'Tables';
  
  if (pathParams[1]) {
    defaultMenuItem = capitalizeFirstLetter(pathParams[1]);
  }
  let [menuItem, setMenuItem] = useState(defaultMenuItem);

  const menusList = {
    'tables': {
        key: "Tables",
        icon: TabletOutlined,
        label: "Tables"
    },
    'rounds': {
      key: "Rounds",
      icon: InsertRowBelowOutlined,
      label: "Rounds"
    },
    'dealers': {
      key: "Dealers",
      icon: TeamOutlined,
      label: "Dealers"
    },
    'bets': {
      key: "Bets",
      icon: DollarOutlined,
      label: "Bets",
      role: "superadmin"
    },
    'me': {
      key: "Me",
      icon: SolutionOutlined,
      label: "Edit Account"
    },
    'logout': {
      key: "logout",
      icon: LogoutOutlined,
      label: "Logout"
    }
  }
  const navigate = useNavigate();
  const handleMenuSelect = (item) => {
    if (item.key === 'logout') {
      if (window.confirm("Are you sure?")) {
        authData.clear();
        window.location.reload();
      }
    }
    else {
      navigate(`/${item.key.toLowerCase()}`);
      setMenuItem(item.key);
    }
  };

  useEffect(() => {
    if (defaultMenuItem) {
      setMenuItem(defaultMenuItem)
    }
  }, [defaultMenuItem])
 
  return (
    <Layout className='main'>
      <Sider
        collapsible
        breakpoint="lg"
        collapsedWidth="0"
        onBreakpoint={(broken) => {
          console.log(broken);
        }}
        onCollapse={(collapsed, type) => {
          console.log(collapsed, type);
        }}
      >
        <div className="logo"><img src="/images/logo.svg"  onClick={()=>navigate("/")} /></div>
        <Menu
          theme="dark"
          mode="inline"
          onClick={handleMenuSelect}
          selectedKeys={[menuItem]}
          items={Object.values(menusList).map(
            (menuOptions, index) => {
              if (menuOptions.role && (menuOptions.role !== userStore.user.role)) {
                return;
              }
              return ({
                key: menuOptions.key,
                icon: React.createElement(menuOptions.icon),
                label: menuOptions.label,
              })
            },
          )}
        />
      </Sider>
      <Layout>
        <Content
          style={{
            margin: '24px 16px 0',
          }}
          className="content"
        >
          <div
            style={{
              padding: 24,
              minHeight: 360,
              background: "#12152b",
            }}
          >
            {
              printComponent(menuItem)
            }
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default AdminPanel;