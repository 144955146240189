import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    bets: [],
    cuurentRound: {},
    total: 0
};

const betSlice = createSlice({
    name: "bets",
    initialState,
    reducers: {
        setBets(state, action){
            state.bets = action.payload.rows;
            state.total = action.payload.count;
        }
    }
});

export const betActions = betSlice.actions;
export const betReducer = betSlice.reducer;