import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import authData from "../../services/authData";

const initialState = {
    loaded: false,
    isLoggedin: false,
    dealers: []
};

export const useLogin = createAsyncThunk('users/me', async() => {
    return {}
})

export const Me = createAsyncThunk('users/me', async() => {
    return {}
})

const dealersSlice = createSlice({
    name: "delaers",
    initialState,
    reducers: {
        setDealers(state, action) {
            state.dealers = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(Me.fulfilled, (state, action) => {
            state.loaded = true;
        })
    }
});

export const dealerActions = dealersSlice.actions;
export const dealerReducer = dealersSlice.reducer;