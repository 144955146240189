import { Row, Col } from "antd";
import { useEffect, useRef, useState } from "react";
import "./chatContainer.scss";
import {WechatOutlined, CloseOutlined} from '@ant-design/icons'

const ChatContainer = ({children, headerTitle = ''}) => {
    const [open, setOpen] = useState(false);
    const hideClassName = !open ? 'hide': '';
    const chatRef = useRef();

    const toogleLiveChat = () => {
        setOpen((prev) => !prev);
    }


    useEffect(() => {
        if (chatRef.current) {
            chatRef.current.scrollTop = chatRef.current.scrollHeight;
        }
    });

    return (<Row className="chatContainer">
        
        <Row className={`chatWrapper ${hideClassName}`}>
            <Col span={12}>
                <Row className="header">
                    <Col span="10" className="header-title">
                        {headerTitle}
                    </Col>
                    <Col span="14" className="close-wrapper">
                        <CloseOutlined className="close-chat" onClick={toogleLiveChat} />
                    </Col>
                </Row>
                <Row className={`body`} ref={chatRef}>
                    {children}
                </Row>
            </Col>
        </Row>
        <Row className="icon-wrapper" onClick={toogleLiveChat}>
            {!open ? (<WechatOutlined />): (<CloseOutlined />)} 
        </Row>
    </Row>)
}

export default ChatContainer;