import { Row } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useOutlet } from 'react-router-dom';
import SocketContext from '../../../../store/SocketContext';
import Table from './Table/Table';

function TablesView() {
    const socket = useContext(SocketContext);
    useEffect(() => {
        if (socket) {
            socket.emit("getTables")
        }
    }, [socket])
    const tables = useSelector(state => state.dashboard.tablesList);
    const outlet = useOutlet()

    return(outlet || <Row>
        {tables.map((table) => <Table key={table.port} table={table}/>)}
    </Row>)
}

export default  TablesView;