import React, { useContext, useEffect, useState } from "react";
import { Typography, Row, Col, Input, Modal, Popconfirm, DatePicker, message as antDMessage } from "antd";
import { useDispatch, useSelector } from "react-redux";
import "./TableFooter.scss";
import GameSocketContext from "../../../../../../../store/GameSocketContext";
import { chatActions } from "../../../../../../../store/slice/chatSlice";
import ChatContainer from "../../../../../../partial/chatContainer/chatContainer";
import { DeleteOutlined} from '@ant-design/icons'

const TableFooter = () => {
    const chat = useSelector(store => store.chat);
    const user = useSelector(store => store.user);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [blockedDateTime, setBlockedDateTime] = useState(null);
    const [messageApi, contextHolder] = antDMessage.useMessage();
    const dispatch = useDispatch();
    
    const [message, setMessage] = useState('');
    const [diabalePlayer, setDisablePLayer] = useState('');
    const [blockMessageId, setBlockMessageId] = useState(null);

    const gameSocket = useContext(GameSocketContext);

    const handleScaleChange = (newScale) => {
        const messageElements = document.querySelectorAll(".message");
        messageElements.forEach((element) => {
          element.style.transform = `scale(${newScale})`;
        });
    };

    useEffect(() => {
        if (!gameSocket) {return;}
        gameSocket.on("setDeletedMessageId", (messageId) => {
            dispatch(chatActions.deletePlayerToDealerMessage(messageId))
        })
        gameSocket.on("sendChatMessage", (message) => {
            dispatch(chatActions.addPlayerToDealerMessage(message))
        });
        gameSocket.emit("getPlayerChatMessages")
        gameSocket.on("playerChatMessages", (messages) => {
            dispatch(chatActions.updatePlayerToDealerMessages(messages))
        });
        gameSocket.on("blockedPlayersIds", (blockedIds) => {
            dispatch(chatActions.setBlockedIds(blockedIds));
        });
        gameSocket.on("setBlockedPlayer", (playerId) => {
            dispatch(chatActions.updateBlockedId(playerId))
        })
        gameSocket.on("newMessage", (response) => {
            dispatch(chatActions.addMessage(response));
        });
        gameSocket.on("clearMessages", () => {
            dispatch(chatActions.clearMessages());
        });
    }, [gameSocket])

    const sendMessage = () => {
        let newMessage = message.trim();

        if (!newMessage) {
            return;
        }
        gameSocket.emit('managerMessage', {message: newMessage, user: user.user});
        setMessage('');
    };
 
    const handleOpenModal = (playerId, messageId) => {
        setIsModalVisible(true);
        setDisablePLayer(playerId)
        setBlockMessageId(messageId)
    };
    
    const handleBlock = () => {
        if (!blockedDateTime) {
            messageApi.open({
                type: 'error',
                content: 'Please select a date and time.',
            });
            return;
        }
        blockUser(diabalePlayer, blockedDateTime, blockMessageId);
        setBlockedDateTime(null);
        setBlockMessageId(null);
        setIsModalVisible(false);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
        setDisablePLayer('');
    }

    const handleDateTimeChange = (date) => {
        if (date) {
          setBlockedDateTime(date);
        } else {
          setBlockedDateTime(null);
        }
    };

    const blockUser = (playerId, date, messageId) => {
        gameSocket.emit("blockUser", {
            playerId,
            date,
            messageId
        })
    }

    const deleteMessage = (messageId) => {
        gameSocket.emit("deletePlayerMessageById", messageId)
    }
    const unblockUser = (playerId) => {
        gameSocket.emit("unBlockUser", playerId);
    }

    return (<Row className="mt-3 pt-11">
        <ChatContainer headerTitle="Player Chat">
            <Row className="player-chat-wrapper">
                <Col span={24}>
                    {!chat.playerToDealerMessages.length ? <Row style={{justifyContent: "center"}}>
                        <Typography>Chat is empty</Typography>
                    </Row>: ''}
                    {chat.playerToDealerMessages && chat.playerToDealerMessages.map((messageObj, index) => {
                        return (
                            <Row key={messageObj.message+index} className="player-chat-message">
                                <Col span={20}>
                                    <Typography.Text className='message-text'>
                                        <Typography.Text>{messageObj.playerUserName ? `${messageObj.playerUserName} - ${messageObj.message}`: messageObj.message}</Typography.Text>
                                        <Typography.Text className="chat-time">{new Date(messageObj.createdAt).toLocaleTimeString()}</Typography.Text>
                                    </Typography.Text>
                                </Col>
                                <Col span={4} className="manager-actions">
                                    <Popconfirm title="Are you sure you want to delete the message?" onConfirm={() => deleteMessage(messageObj.id)}>
                                        <DeleteOutlined className="delte-message" title="Delete"/>
                                    </Popconfirm>
                                    {chat?.blockedIds && !chat?.blockedIds?.includes(messageObj.playerId) ? (<Typography.Text onClick={() => handleOpenModal(messageObj.playerId, messageObj.id)}>
                                        Block
                                    </Typography.Text>): (
                                        <Popconfirm title="Are you sure you want to unblock the player?" onConfirm={() => unblockUser(messageObj.playerId)}>
                                            <Typography.Text>Unblock</Typography.Text>
                                        </Popconfirm>)}
                                </Col>
                            </Row>
                        )
                    })}
                </Col>
            </Row>
        </ChatContainer>
        <Modal title="Select Blocked Date and Time" open={isModalVisible} onOk={handleBlock} onCancel={handleCloseModal}>
            <DatePicker
                showTime={{ format: 'HH:mm' }}
                format="YYYY-MM-DD HH:mm"
                disabledDate={(current) => current && current < new Date().setHours(0, 0, 0, 0)}
                onChange={handleDateTimeChange}
                value={blockedDateTime}
            />
            {contextHolder}
        </Modal>
    </Row>)
}

export default TableFooter;