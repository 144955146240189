import { Button, Col, Row, Typography, Space, Divider, Input, Popconfirm, message as messageNot } from "antd";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GameSocketContext from "../../../../../../../store/GameSocketContext";
import { dashboardActions } from "../../../../../../../store/slice/dashboardSlice";
import "./ManagerActions.scss";
import { WarningOutlined, CloseOutlined } from '@ant-design/icons';
import ChatBox from "../../../../../../partial/chatBox/chatBox";

const ManagerActions = ({sounds}) => {
    const chat = useSelector(store => store.chat);
    const user = useSelector(store => store.user);
    const game = useSelector(state => state.game);
    const socket = useContext(GameSocketContext);
    const gameSocket = useContext(GameSocketContext);
    const dashboard = useSelector((store) => store.dashboard);
    const [showCancelReason, setShowCancelReason] = useState(false);
    const [reason, setReason] = useState("");
    const [message, setMessage] = useState('');
    const dispatch = useDispatch();
    const {messages} = chat;

    const fixMistake = () => {
        socket.emit("managerFixedMistake");
    }
    const pause = () => {
        socket.emit("managerPauseToggle");
    }
    const cancelGame = (e) => {
        e.preventDefault()
      
        if (!reason) {
            messageNot.error('Reason is required');
            return;
        }
        socket.emit("cancelGame", {reason: reason});
        // Todo:  move highlight to cancel
        setReason("");
        setShowCancelReason(false);
    }

    const toggleTableStatus = () => {
        socket.emit('toggleTableStatus')
    }

    useEffect(() => {
        if (dashboard?.showTalk?.allow) {
            setTimeout(() => {
                dispatch(dashboardActions.disabeleShowTalk())
            }, 5000)
        }
    }, [dashboard?.showTalk?.allow]);

    const seenMessages = () => {
        ['talkAudio', 'mistakeAudio', 'changeMe'].forEach((currentSound) => {
            const sound = sounds[currentSound];

            if (!sound.paused) {
                sound.pause();
                sound.currentTime = 0;
            }
        });
    }

    const clearMessages = () => {
        gameSocket.emit('clearMessages');
    } 

    const sendMessage = () => {
        let newMessage = message.trim();

        if (!newMessage) {
            return;
        }
        gameSocket.emit('managerMessage', {message: newMessage, user: user.user});
        setMessage('');
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            sendMessage()
        }
    };

    const changeDealer = () => {
        dispatch(dashboardActions.disabeleChangeMe())
        seenMessages();
    }

    return(
        <>
            <Divider orientation="left" className="mt-8">Manager Actions</Divider>
            <Row style={{padding: 24,background: "#001529"}}>
                <Col span={12}>
                    <Space wrap>
                        <Button className="primary-color" onClick={pause}>{game.paused ? 'Paused': 'Pause'}</Button>
                        <Button className="secondary-color" onClick={() => setShowCancelReason((prev) => !prev)}>Cancel Game</Button>
                        <Button className="primary-color manager-btn-info" onClick={seenMessages}>Mute</Button>
                        <Popconfirm title="Are you sure?" onConfirm={toggleTableStatus}>
                                <Button style={{
                                    background: (game?.table?.isOnline === 1 ? 'red': 'green')
                                }}>{game?.table?.isOnline === 1 ? 'Make Offline': 'Make Online'}</Button>
                            </Popconfirm>
                            <Button onClick={clearMessages}>Clear Chat History</Button>
                    </Space>
                    {showCancelReason &&
                        <Row gutter={16} style={{marginTop: "10px"}}>
                            <Col span={20}>
                                <Input 
                                    size="large" 
                                    placeholder="Round cancel reason"
                                    onChange={(e) => setReason(e.target.value)}
                                    value={reason}
                                />
                            </Col>
                            <Col span={4}>
                                <Popconfirm
                                    title="Cancel round"
                                    description="Are you sure to cancel the round?"
                                    okText="Yes"
                                    cancelText="No"
                                    onConfirm={cancelGame}
                                >
                                    <Button size="large" type="primary" danger >Submit</Button>
                                </Popconfirm>
                            </Col>
                        </Row>}
                </Col>
                <Col span={11} style={{marginLeft: 'auto'}}>
                    <div className="mistake-wrapper">
                        {(game.mistake || dashboard?.showTalk?.allow || dashboard?.changeMe?.allow) && <Space className="title">
                        {game.mistake && <><WarningOutlined /><Typography>Mistake</Typography><CloseOutlined onClick={fixMistake} /></>}
                        {dashboard?.showTalk?.allow && <><WarningOutlined /><Typography>Talk</Typography><CloseOutlined onClick={seenMessages} /></>}
                        {dashboard?.changeMe?.allow && <><WarningOutlined /><Typography>Change Me</Typography><CloseOutlined onClick={changeDealer} /></>}
                        </Space>}
                        <div className="chat-wrap">
                            <ChatBox messages={messages}/>
                            <Input onKeyDown={handleKeyPress} onChange={(e) => setMessage(e.target.value)} value={message} suffix={<Button onClick={sendMessage}>Chat Msg</Button>} />                            
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default ManagerActions;