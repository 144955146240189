import { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GameSocketContext from "../../../../../../store/GameSocketContext";
import Belote from "./Games/Belote/Belote"
import { gameActions } from "../../../../../../store/slice/gameSlice";
import { dashboardActions } from "../../../../../../store/slice/dashboardSlice";
import { Row, Col } from "antd";
import ManagerActions from "./ManagerActions/ManagerActions";

const TableGameContent = ({table}) => {

    const gameSocket = useContext(GameSocketContext)
    const game = useSelector((store) => store.game);
    const dispatch = useDispatch();
    const [audioSound, setAudioSound] = useState();
    const talkAudio = useMemo(() => new Audio('/mistake.mp3'), []);
    const mistakeAudio = useMemo(() => new Audio('/mistake.mp3'), []);
    const changeMe = useMemo(() => new Audio('/mistake.mp3'), []);

    useEffect(() => {
       if (gameSocket) {
            gameSocket.on('connect', () => {
                gameSocket.emit("refreshGame")
            })
            gameSocket.on("gameUpdate", (data) => {
                dispatch(gameActions.update(data))
            });
            gameSocket.emit("getTables")
            gameSocket.on("talk", () => {
                setAudioSound(talkAudio);
                talkAudio.play(); 
                dispatch(dashboardActions.enableShowTalk())
            });
            gameSocket.on("changeMe", () => {
                setAudioSound(changeMe);
                changeMe.play();
                dispatch(dashboardActions.enableChangeMe())
            });
       }
        
    }, [gameSocket]);

    useEffect(() => {
        if (game.mistake) {
            setAudioSound(mistakeAudio);
            mistakeAudio.play()
        }
    }, [game.mistake])

    let adminOptions = {
        removeCard: (cardName) => {
            const conf = window.confirm("Are you sure?");
            if (!conf) {
               return false;
            }
            gameSocket.emit('removeCard');
        },
        showRemove: true
    }

    return (
        <>
            <Row>
                <Col span="24">
                    {(table.game.identifier === 'belote') && <Belote table={table} adminOptions={adminOptions}/> }
                </Col>
            </Row>
            <ManagerActions sounds={{talkAudio, mistakeAudio, changeMe}}/>
        </>
    )
}

export default TableGameContent;