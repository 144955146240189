import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import 'antd/dist/antd'
import App from './App';
import { Provider } from 'react-redux';
import store from "./store";
import reportWebVitals from './reportWebVitals';
import { BrowserRouter}  from 'react-router-dom';
import SocketProvider from './store/SocketProvider';
import LanguageProvider from './store/LanguageProvider';

const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
  <Provider store={store}>
    <SocketProvider>
      <BrowserRouter>
      <LanguageProvider>
        <App />  
      </LanguageProvider> 
      </BrowserRouter>
    </SocketProvider>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
