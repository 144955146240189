import './App.css';
import { useSelector } from 'react-redux';
import AdminLayout from "./components/AdminLayout";
import { Route, Routes } from "react-router-dom";
import { ConfigProvider, theme  } from "antd";
import TableContent from './components/AdminPanel/menus/Tables/Table/TableContent/TableContent';
import TablesView from './components/AdminPanel/menus/Tables/TablesView';
import DealerCreate from './components/AdminPanel/menus/Dealers/DealerCreate';
import { lazy } from 'react';

const DealersView = lazy(() => import('./components/AdminPanel/menus/Dealers/DealersView'));
const RoundView = lazy(() => import('./components/AdminPanel/menus/Rounds/RoundView'));
const RoundsView = lazy(() => import('./components/AdminPanel/menus/Rounds/RoundsView'));
const BetsView = lazy(() => import('./components/AdminPanel/menus/Bets/BetsView'));
const MeView = lazy(() => import("./components/AdminPanel/menus/Me/MeView"));

function App() {
  const { defaultAlgorithm, darkAlgorithm } = theme;
  const isDarkMode =  useSelector((state) => state.dashboard.themeMode);

  return (
    <ConfigProvider className="App" theme={{
      algorithm: isDarkMode ? darkAlgorithm : defaultAlgorithm,
    }}>
      <Routes>
          <Route path='/' Component={AdminLayout}>
            <Route path='tables' Component={TablesView} />
            <Route path='tables/:tableId' Component={TableContent}/>
            <Route path='dealers/:dealerId' Component={DealerCreate} />
            <Route path='dealers' Component={DealersView}/>
            <Route path='rounds' Component={RoundsView} />
            <Route path='rounds/:roundId' Component={RoundView} />
            <Route path='bets' Component={BetsView} />
            <Route path='me' Component={MeView} />
          </Route>
        </Routes>
    </ConfigProvider>
  );
}

export default App;
