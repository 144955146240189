import i18n from "i18next";
import { initReactI18next } from 'react-i18next';
import Backend from "i18next-xhr-backend";
import LanguageContext from "./LanguageContext"

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: "en-US",
    fallbackLng: "en",
    debug: false,
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    interpolation: {
      escapeValue: false,
    },
    });

const LanguageProvider = ({ children }) => {
    return <LanguageContext.Provider value={i18n}>
        {children}
    </LanguageContext.Provider>
}

export default LanguageProvider;