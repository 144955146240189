import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    rounds: [],
    cuurentRound: {},
    total: 0
};

const roundSlice = createSlice({
    name: "rounds",
    initialState,
    reducers: {
        setRounds(state, action){
            state.rounds = action.payload.rows;
            state.total = action.payload.count;
        },
        pushRounds(state, action) {
            state.rounds.push(action.payload);
        },
        setCurrentRound(state, action) {
            state.cuurentRound = action.payload;
        }
    }
});

export const roundActions = roundSlice.actions;
export const roundReducer = roundSlice.reducer;