import { Col, Row, Typography, Space } from "antd";
import Card from "../../../../../../../partial/card";
import TrumpCard from "./TrumpCard";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Combinations from "./Combinations";
import { useContext, useEffect } from "react";
import GameSocketContext from "../../../../../../../../store/GameSocketContext";
import { useTranslation } from "react-i18next";

const getPlayerCombination = (list) => {
    const combinations = [];
    if (!list) {
        return false;
    }
    Object.keys(list).forEach((current) => {
        if (list[current].length && current !== 'status') {
            combinations.push(current);
        }
    });
    if (!combinations.length) {
        return false;
    }
    return combinations;
}

const BeloteContent = ({game, adminOptions, showRemove = true}) => {
    const {currentConf} = game;
    const {cards, resultInfo, currentRound = {}} = game;
    console.log("resultInfo?.combination ", resultInfo?.combination)
    const coefficient = resultInfo?.coefficient;
    const {Title, Text} = Typography;
    const stages = ["Start", "Betting", "Dealing", "Results", "Cancel"];
    const dashboard = useSelector(state => state.dashboard);
    const {tableId} = useParams();
    const socket = useContext(GameSocketContext)
    
    const table = dashboard.tablesList.filter((table) => table.id !== tableId)[0];
    const { t } = useTranslation("translation");

    useEffect(() => {
        if (socket) {
            socket.emit("join", 'Manager');
        }
    }, [socket])

    const allowShowRemove = (card) => {
        if (!showRemove) {
            return showRemove;
        }
        let status = false;
        if (game.cards?.opendCards.at(-1) === card && game.cards?.dealingStageCards.length && !["Cancel", 'Results'].includes(currentConf?.stage)) {
            status = true;
        }
        
        return status;
    }

    const getPlayerHandClass = (player) => {
        return currentRound.hand == player ? 'hand-active': '';
    }

    const getCardCombinationCalss = (player, cardValue) => {
        if (!resultInfo?.combination || resultInfo?.combination[player]) {
            return '';        
        }
        let playerStr = player === 'p1' ? 'player1':  'player2';
        let className = '';

        const data = getPlayerCombination(resultInfo?.combination[playerStr]);
        if (!data) {
            return '';
        }
        const getCardStr = (currentCard) => {
            return (currentCard.rank != 10? currentCard.rank: 'T')+(currentCard.suit?.at(0).toUpperCase())
        }

        data.forEach(combinationName => {
            resultInfo?.combination[playerStr][combinationName].forEach((combinationCards) => {
                if (combinationCards) {
                    if (combinationName !== 'belote') {
                        const data = combinationCards?.find(currentCard => {
                            return getCardStr(currentCard) === cardValue;
                        })
                        if (data) {
                            className +=  ` card-combination ${combinationName}`
                        }
                    }
                    else if (combinationName === 'belote' && getCardStr(combinationCards) === cardValue) {
                        className +=  ` card-combination ${combinationName}`
                    }
                }
            });
        });

        return className;
    }

    const RenderCard = ({cardNumber, i, player}) => {
        if (!cards) {
            return '';
        }
        let cardName = 'emptyCard';
        if (!!cards[player] && cards[player][cardNumber]) {
            cardName = cards[player][cardNumber].value;
        }
        if (game.currentConf?.player === player && game.currentConf?.card-1 === (cardNumber) && cardName === 'emptyCard') {
            cardName = 'emptyActiveCard';
        }
        const className = getCardCombinationCalss(player, cardName);
        return (
            <Card key={player+i} cardName={cardName} className={className}  adminOptions={{...adminOptions, showRemove: allowShowRemove(cardName)}} />  
        )
    }

    return (<Row className="belot-board-content">
        <Col span={24} className="configs-col">
            <div className="configs-wrapper mb-2">
                <Text className="stage-container">Round: <b>{game?.currentRound?.id}</b></Text>
                {stages.map((current, i) => {
                    let extraClass = '';
                    if (currentConf && current === currentConf?.stage) {
                        extraClass = 'active-stage';
                    }
                    return (<Text key={current} className={`stage-container ${extraClass}`}>
                        {current}
                        {(extraClass && currentConf?.stage === 'Betting') && <Text className="betting-time"> {currentConf.current}</Text>}
                    </Text>)
                })}
            </div>
        </Col>
        <Col span={11} className="players-wrapper">
            <Row>
                <Col span={8} className={`player-title-wrapper ${getPlayerHandClass(1)}`}>
                    <Title level={2}>P1  <Text level={4}>Score: <span>{game.resultInfo?.score?.player1 ?? 0}</span></Text></Title>
                </Col>
                <Col span={8} className="player-title-wrapper player-score">
                    {coefficient?.player1 && Object.keys(coefficient?.player1).map((current, i) => {
                        return (
                            <Space key={'p1'+current+''+i}>
                                <Text>{t(current)}</Text>
                                <Title level={4}>{coefficient.player1[current]}</Title>
                            </Space>
                        )
                    })}
                </Col>
            </Row>
            <Row className="cards-wrapper">
                <Col span={24} className="cards-section">
                    {
                        [0,1,2].map((e, i) => {
                            return (<RenderCard key={"p1"+e+i} cardNumber={e} i={i} player="p1" />)
                        })
                    }
                </Col>
                <Col span={24} className="cards-section">
                    {
                        [3,4,5,6,7,8].map((e, i) => {
                            return (<RenderCard key={"p1"+e+i} cardNumber={e} i={i} player="p1" />)
                        })
                    }
                </Col>
            </Row>
            <Combinations combinations={getPlayerCombination(resultInfo?.combination?.player1)} player="p1" canceled={!!resultInfo?.combination?.player1?.status}/>
        </Col>
        <Col span={2} className="trump-wrapper" style={{zIndex: 111}}>
            <Space className="align-center">
                <Text level={2}>Draw</Text>
                <Title level={4} style={{margin: 0}}>{resultInfo?.coefficient?.draw}</Title>
            </Space>
            <Title className="align-center">Trump</Title>
            <div className="align-center" style={{width: "100%"}}>
                <TrumpCard game={game} allowShowRemove={allowShowRemove}  adminOptions={{...adminOptions}}/>
            </div>
        </Col>
        <Col span={11} className="players-wrapper player2-wrapper">
            <Row className="player2-info-wrapper">
                <Col span={8} className={`player-title-wrapper ${getPlayerHandClass(2)}`}>
                    <Title level={2}>P2 <Text level={4}>Score: <span>{game.resultInfo?.score?.player2 ?? 0}</span></Text></Title>
                </Col>
                <Col span={8} className="player-title-wrapper player-score">
                    {coefficient?.player2 && Object.keys(coefficient?.player2).map((current, i) => {
                        return (
                            <Space key={'p1'+current+''+i}>
                                <Text>{t(current)}</Text>
                                <Title level={4}>{coefficient.player2[current]}</Title>
                            </Space>
                        )
                    })}
                </Col>
            </Row>
            <Row className="cards-wrapper">
                <Col span={24} className="cards-section">
                    {
                        [0,1,2].map((e, i) => {
                            return (<RenderCard key={"p2"+e+i} cardNumber={e} i={i} player="p2" />)
                        })
                    }
                </Col>
                <Col span={24} className="cards-section">
                    {
                        [3,4,5,6,7,8].map((e, i) => {
                            return (<RenderCard key={"p2"+e+i} cardNumber={e} i={i} player="p2" />)
                        })
                    }
                </Col>
            </Row>
            <Combinations combinations={getPlayerCombination(resultInfo?.combination?.player2)} player="p2" canceled={!!resultInfo?.combination?.player2?.status}/>
        </Col>
    </Row>)
}

export default BeloteContent;