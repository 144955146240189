import React, { useState, useEffect } from 'react';
import SocketContext from './SocketContext';
import io from 'socket.io-client';
import { getToken } from '../helpers/HttpClient';

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const token = getToken();
    const apiURL = process.env.REACT_APP_API_URL || "http://localhost:3016";

    const newSocket = io(`${apiURL}`, {
        transports: ["websocket", "polling", "flashsocket"],
        auth: {
            token: token,
        },
        reconnection: true,
        reconnectionAttempts: 1000,
        reconnectionDelay: 1000,   
        reconnectionDelayMax: 5000, 
    });
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;