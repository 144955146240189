import React, { useState, useEffect } from 'react';
import GameSocketContext from './GameSocketContext';
import io from 'socket.io-client';
import { getToken } from '../helpers/HttpClient';

const SocketProvider = ({ children, gameAPIURL }) => {
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const token = getToken();
    const apiURL = process.env.REACT_APP_API_URL;
    const newSocket = io(`${gameAPIURL}`, {
        transports: ["websocket", "polling", "flashsocket"],
        auth: {
            token: token,
        },
        reconnection: true,
        reconnectionAttempts: 1000,
        reconnectionDelay: 1000,   
        reconnectionDelayMax: 5000, 
    });
     setSocket(newSocket);

     return () => newSocket.close();
  }, []);

  return (
    <GameSocketContext.Provider value={socket}>
      {children}
    </GameSocketContext.Provider>
  );
};

export default SocketProvider;