import { Row, Col, Typography, Input, Button } from "antd";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from 'yup';
import SocketContext from "../../../../store/SocketContext";
import "./Dealers.scss";
const {Title} = Typography;

const DealerCreate = () => {
    const socket = useContext(SocketContext);

    let [dealer, setDealer] = useState({
      firstName: '',
      lastName: '',
      nickName: '',
      barcode: ''
    });
    const [reInit, setReInit] = useState(false);
    const navigate = useNavigate();
    const {dealerId} = useParams();
 
    const validationSchema = Yup.object({
        firstName: Yup.string().required(),
        lastName: Yup.string().required(),
        nickName: Yup.string().required(),
        barcode: Yup.string().required()
    });

    const handleSubmit = (values) => {

        if (!+dealerId) {
          socket.emit('createDealer', values);
        }
        else {
          socket.emit('updateDealer', {dealerId, values })
        }
        navigate("/");
    }

    useEffect(() => {
      if (+dealerId) {
        socket.emit('getDealer', dealerId);
        socket.on('setDealer', (dealer) => {
          setDealer({...dealer})
          setReInit(true);
        });
      }
    }, [dealerId]);

    return (<Row>
        <Col span={24}>
            <Title>Create Dealer</Title>
            <Formik
                initialValues={dealer}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                enableReinitialize={reInit}
            >
                {({ values, handleChange, errors, setFieldTouched, touched, isValid, handleSubmit }) => {
                  return (
                    <Form className="form">
                        <div className="mb-2 pb-5">
                          <Field name="firstName">
                              {({ field, form }) => (
                                <Input
                                  {...field}
                                  value={values.firstName}
                                  type="text"
                                  placeholder="Firstname"
                                  onChange={e => form.setFieldValue('firstName', e.target.value)}
                                />
                              )}
                          </Field>
                          <ErrorMessage component="div" className="error" name="firstName" />
                        </div>
                        <div className="mb-2 pb-5">
                          <Field name="lastName">
                              {({ field, form }) => (
                                <Input
                                {...field}
                                value={values.lastName}
                                  type="text"
                                  placeholder="Last name"
                                  onChange={e => form.setFieldValue('lastName', e.target.value)}
                                />
                              )}
                          </Field>
                          <ErrorMessage component="div" className="error" name="lastName" />
                        </div>
                        <div className="mb-2 pb-5">
                          <Field name="nickName">
                              {({ field, form }) => (
                                <Input
                                  {...field}
                                  value={values.nickName}
                                  type="text"
                                  placeholder="Nickname"
                                  onChange={e => form.setFieldValue('nickName', e.target.value)}
                                />
                              )}
                          </Field>
                          <ErrorMessage component="div" className="error" name="nickName" />
                        </div>
                        <div className="mb-2 pb-5"> 
                          <Field name="barcode">
                              {({ field, form }) => (
                                <Input
                                  {...field}
                                  value={values.barcode}
                                  type="text"
                                  placeholder="Barcode"
                                  onChange={e => form.setFieldValue('barcode', e.target.value)}
                                />
                              )}
                          </Field>
                          <ErrorMessage component="div" className="error" name="barcode" />
                        </div>  
                        <Button type="primary" className="ml-auto" onClick={handleSubmit}>Save</Button>
                    </Form>
                  )
                }}
            </Formik>
        </Col>
    </Row>)
}

export default DealerCreate;