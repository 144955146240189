import { Col, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GameSocketProvider from "../../../../../../store/GameSocketProvider";
import SocketContext from "../../../../../../store/SocketContext";
import "./TableContent.scss"
import TableFooter from "./TableFooter/TableFooter";
import TableGameContent from "./TableGameContent";

const TableContent = () => {
    const {tableId} = useParams();
    const [table, setTable] = useState({});
    const socket = useContext(SocketContext);

    useEffect(() => {
        if (socket) {
            socket.emit('getTable', tableId);
            socket.on('setTable', (tableData) => {
                setTable(tableData);
            })
        }
    }, [socket])
    if (!table.port) {
        return (<></>)
    }

    return (<Row className="table-content-wrapper">
        <Col span={24}>
            <GameSocketProvider gameAPIURL={table.APIURL}>
                <TableGameContent table={table}/>
                <TableFooter />
            </GameSocketProvider>
        </Col>
    </Row>)
}

export default TableContent;