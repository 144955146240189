import {useNavigate } from 'react-router-dom';
import { Card, Typography, Col, Tag  } from 'antd';


const Table = ({table}) => {
    const navigate = useNavigate();
    const openTable = () => {
        navigate(`/tables/${table.id}`)
    }
 
    return (<Col span={8}>
        <Card 
            title={`Table_${table.gameId}`} 
            extra={<Tag color="#1677ff">b1 c2</Tag>} 
            actions={[
                <a href="#" onClick={openTable}>Manage</a>
            ]}
        >
            <Typography>Dealer: <b>{table?.dealer?.firstName} {table?.dealer?.lastName}</b></Typography>
            <Typography>Game Status: <b>{table.name}</b></Typography>
        </Card>
    </Col>)
}

export default Table;